<template>
  <TalpaLoaderWrapper class="loader-wrapper" v-if="$apollo.loading" />
  <AssetStyled v-else-if="asset">
    <header>
      <GoBackBar class="go-back" :title="asset.name" :largeTitle="true" />
      <SwitchChildRouteAtom v-if="!hasMaintenanceAppPermission && !hasSDAppPermission" class="routes" :childRoutes="childRoutes" />
    </header>
    <main>
      <router-view />
    </main>
  </AssetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TalpaLoaderWrapper } from '@common/components'
import permissionsMixin from '@/mixins/permissions'
import ASSET_QUERY from '#/graphql/operations/asset/assetWithLastPosition.gql'
import GoBackBar from '@/components/Atomic/Atoms/GoBackBar'
import SwitchChildRouteAtom from '../Atomic/Atoms/SwitchChildRouteAtom.vue'

const AssetStyled = styled('div')`
  display: grid;
  grid-template-rows: min-content calc(100% - 100px);
  grid-template-columns: 1fr min-content;
  grid-gap: 1rem;
  padding: 1rem;
  height: calc(100% - 2rem);
  .go-back {
    background: transparent;
    padding: 0;
    font-size: 1.25rem;
  }
  > header {
    display: flex;
    flex-direction: column;
    > .routes {
      padding-left: 3rem;
    }
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  > main {
    display: grid;
    grid-column: span 2;
    padding-right: 1rem;
    @media (min-width: 768px) {
      overflow: auto;
    }
  }
`

export default {
  name: 'Asset',
  mixins: [permissionsMixin],
  components: {
    TalpaLoaderWrapper,
    AssetStyled,
    GoBackBar,
    SwitchChildRouteAtom,
  },
  data() {
    return {
      asset: null,
    }
  },
  computed: {
    hasMaintenanceAppPermission() {
      return Boolean(this.hasMaintenanceAppV2Permission)
    },
    hasSDAppPermission() {
      return Boolean(this.hasServiceDealerAppPermission)
    },
    childRoutes() {
      return [
        {
          name: 'AssetOverview',
          label: this.$tc('overview', 1),
        },
        {
          name: 'AssetLog',
          label: this.$tc('errorLog', 1),
        },
      ]
    },
  },
  apollo: {
    asset: {
      query: ASSET_QUERY,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      skip() {
        return !this.$route?.params?.id
      },
    },
  },
}
</script>
