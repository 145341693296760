var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading ? _c('TalpaLoaderWrapper', {
    staticClass: "loader-wrapper"
  }) : _vm.asset ? _c('AssetStyled', [_c('header', [_c('GoBackBar', {
    staticClass: "go-back",
    attrs: {
      "title": _vm.asset.name,
      "largeTitle": true
    }
  }), !_vm.hasMaintenanceAppPermission && !_vm.hasSDAppPermission ? _c('SwitchChildRouteAtom', {
    staticClass: "routes",
    attrs: {
      "childRoutes": _vm.childRoutes
    }
  }) : _vm._e()], 1), _c('main', [_c('router-view')], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }